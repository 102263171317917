/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import {
  rrfConfig,
  firebaseConfig
} from "./config/store/firebase/firebaseConfig";
// import fire from "./config/store/firebase/fire";
import configureStore from "./config/store/configureStore";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: configureStore.dispatch,
  createFirestoreInstance
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    firebase.auth().onAuthStateChanged(user => {
      // const str = JSON.stringify(user);
      // console.log("Authenticated User: " + str);
      if (user) {
        this.setState({ user });
        // localStorage.setItem("user", user.uid);
      } else {
        this.setState({ user: null });
        // localStorage.removeItem("user");
      }
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Provider store={configureStore}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Switch>
              {!this.state.user && (
                <Route path="/auth" component={AuthLayout} />
              )}
              {this.state.user && (
                <Redirect from="/" to="/admin/dashboard" exact />
              )}
              {this.state.user && (
                <Redirect from="/auth" to="/admin/dashboard" exact />
              )}

              {this.state.user && (
                <Route path="/admin" component={AdminLayout} />
              )}
              {!this.state.user && <Redirect to="/auth" exact />}
            </Switch>
          </ReactReduxFirebaseProvider>
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
