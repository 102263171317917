/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Timeline from "../Pages/Timeline";

// Styling
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends Component {
  state = {
    show: false
  };

  render() {
    const { classes, meetings, logged, votes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>gavel</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Meeting Types</p>
                <h3 className={classes.cardTitle}>
                  {meetings && meetings.length}
                </h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>people</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Members</p>
                <h3 className={classes.cardTitle}>
                  {this.props.members && this.props.members.length}
                </h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>event</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Upcoming Meetings</p>
                <h3 className={classes.cardTitle}>{logged && logged.length}</h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Icon>how_to_vote</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Logged Votes</p>
                <h3 className={classes.cardTitle}>{votes && votes.length}</h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
          </GridItem> */}
        </GridContainer>

        {this.state.show && <Timeline />}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    meetings:
      state.firestore.ordered[`${process.env.REACT_APP_CLIENT}MeetingTypes`],
    logged:
      state.firestore.ordered[
        `${process.env.REACT_APP_CLIENT}MeetingInstances`
      ],
    votes: state.firestore.ordered[`${process.env.REACT_APP_CLIENT}Votes`],
    members: state.firestore.ordered[`${process.env.REACT_APP_CLIENT}Members`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};

export default compose(
  firestoreConnect([
    {
      collection: `${process.env.REACT_APP_CLIENT}MeetingTypes`
    },
    {
      collection: `${process.env.REACT_APP_CLIENT}MeetingInstances`,
      where: [["pending", "==", true]]
    },
    { collection: `${process.env.REACT_APP_CLIENT}Votes` },
    { collection: `${process.env.REACT_APP_CLIENT}Members` }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(dashboardStyle)(Dashboard));
