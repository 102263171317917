/* eslint-disable no-unused-vars */
import React from "react";
import ReactPlayer from "react-player";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  extendedIcon: {
    marginRight: ".5em"
  },
  tablecell: {
    fontSize: "15pt",
    width: "50%"
  },
  header: {
    padding: "5px 20px 20px 20px"
  },
  description: {
    paddingLeft: "20px",
    paddingBottom: "10px"
  }
};

const AgendaDetail = props => {
  const { classes, showTable, agenda } = props;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <div
            style={{
              paddingBottom: "20px"
            }}
          >
            <Fab
              variant="extended"
              aria-label="Close"
              size="large"
              className={classes.fab}
              onClick={showTable}
            >
              <CloseIcon className={classes.extendedIcon} />
              Close
            </Fab>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Card>
            {agenda.result === "Pass" ? (
              <CardHeader color="success" text>
                <CardText color="success">
                  <h4 className={classes.cardTitleWhite}>{agenda.topic} </h4>
                </CardText>
              </CardHeader>
            ) : (
              <CardHeader color="danger" text>
                <CardText color="danger">
                  <h4 className={classes.cardTitleWhite}>{agenda.topic} </h4>
                </CardText>
              </CardHeader>
            )}

            <CardBody>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tablecell}
                      padding="none"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tablecell}
                      padding="none"
                    >
                      Voted
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agenda.members.map(row => (
                    <TableRow key={row.key}>
                      <TableCell
                        align="left"
                        className={classes.tablecell}
                        padding="none"
                      >
                        {row.fullName}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tablecell}
                        padding="none"
                      >
                        {row.vote}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          {agenda.url && (
            <Card>
              <CardBody>
                <ReactPlayer
                  url={agenda.url}
                  playing
                  controls
                  style={{
                    paddingRight: "20px"
                  }}
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
        {agenda.description && (
          <GridItem xs={6}>
            <Paper>
              <Typography
                className={classes.header}
                variant="h5"
                component="h3"
              >
                Description:
              </Typography>
              <Typography className={classes.description} component="p">
                {agenda.description}
              </Typography>
            </Paper>
          </GridItem>
        )}
        {agenda.attachments && (
          <GridItem xs={6}>
            <Paper>
              <Typography
                className={classes.header}
                variant="h5"
                component="h3"
              >
                Attachments:
              </Typography>
              {agenda.attachments.length > 0 ? (
                <ul
                  style={{
                    paddingBottom: "10px"
                  }}
                >
                  {agenda.attachments.map(file => (
                    <li>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography className={classes.description} component="p">
                  There are no attachments on this agenda item.
                </Typography>
              )}
            </Paper>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
};

export default withStyles(style)(AgendaDetail);
