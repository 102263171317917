/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import { CSVLink } from "react-csv";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  ...buttonStyle
};

class MeetingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      dataToDownload: []
    };
    this.download = this.download.bind(this);
  }
  download(event) {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    this.setState({ dataToDownload: currentRecords }, () => {
      this.csvLink.link.click();
    });
  }
  render() {
    const { classes } = this.props;
    const headers = [
      { label: "Type", key: "instance" },
      { label: "Date", key: "createdOn" },
      { label: "Friendly Name", key: "friendlyName" },
      { label: "Topic", key: "topic" },
      { label: "Result", key: "result" }
    ];
    const columns = [
      {
        Header: "Type",
        id: "instance",
        accessor: d => d.instance,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["instance"] }),
        filterAll: true
      },
      {
        Header: "Date",
        id: "createdOn",
        accessor: d => d.createdOn.toDate().toDateString(),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["createdOn"] }),
        filterAll: true
      },
      {
        Header: "Friendly Name",
        id: "friendlyName",
        accessor: d => d.friendlyName,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["friendlyName"] }),
        filterAll: true
      },
      {
        Header: "Topic",
        id: "topic",
        accessor: d => d.topic,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["topic"] }),
        filterAll: true
      },
      {
        Header: "Result",
        id: "result",
        accessor: d => d.result,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["result"] }),
        filterAll: true
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
        resizable: true,
        Cell: props => (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.props.showInfo(props.original);
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
          </div>
        )
      }
    ];
    return (
      <div>
        <div>
          <GridContainer>
            <GridItem xs={12}>
              {" "}
              <Button onClick={this.download} color="warning">
                Download Info
              </Button>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <CSVLink
            data={this.state.dataToDownload}
            headers={headers}
            filename="voteExport.csv"
            className="hidden"
            ref={r => (this.csvLink = r)}
            target="_blank"
          />
        </div>
        <div>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    DigiVote Logged Votes
                  </h4>
                </CardHeader>
                <CardBody>
                  {this.props.votes && (
                    <ReactTable
                      ref={r => (this.reactTable = r)}
                      data={this.props.votes}
                      columns={columns}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                      }
                      defaultPageSize={20}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MeetingTable);
