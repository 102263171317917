/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { firebaseConnect } from "react-redux-firebase";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// custom sytling
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const LoginPage = props => {
  const [creds, setCreds] = useState({
    loginEmail: "",
    loginEmailState: "",
    loginPassword: "",
    loginPasswordState: ""
  });

  const [cardAnimation, setcardAnimation] = useState({
    cardAnimaton: "cardHidden"
  });

  const handleChange = e => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const submitHandler = obj => {
    obj.preventDefault();

    const email = creds.loginEmail;
    const password = creds.loginPassword;

    if (email.trim().length === 0 || password.trim().length === 0) {
      return;
    }
    try {
      props.firebase
        .auth()
        .setPersistence(props.firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return props.firebase
            .auth()
            .signInWithEmailAndPassword(creds.loginEmail, creds.loginPassword);
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
      // props.firebase
      //   .auth()
      //   .signInWithEmailAndPassword(creds.loginEmail, creds.loginPassword);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={props.classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={submitHandler}>
            <Card login className={props.classes[cardAnimation]}>
              <CardHeader
                className={`${props.classes.cardHeader} ${
                  props.classes.textCenter
                }`}
                color="info"
              >
                <h4 className={props.classes.cardTitle}>Please Login</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={creds.loginEmailState === "success"}
                  error={creds.loginEmailState === "error"}
                  labelText="Email Address *"
                  id="loginemail"
                  value={creds.loginEmail}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e),
                    type: "email",
                    name: "loginEmail"
                  }}
                />
                <CustomInput
                  success={creds.loginPasswordState === "success"}
                  error={creds.loginPasswordState === "error"}
                  labelText="Password *"
                  id="loginpassword"
                  value={creds.loginPassword}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e),
                    type: "password",
                    name: "loginPassword"
                  }}
                />
              </CardBody>
              <CardFooter className={props.classes.justifyContentCenter}>
                <Button color="info" simple size="lg" block type="submit">
                  <h4
                    style={{
                      fontWeight: "bold"
                    }}
                  >
                    Login
                  </h4>
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default firebaseConnect()(withStyles(loginPageStyle)(LoginPage));
