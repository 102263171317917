/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

//Material UI Core Components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Material UI Icons

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { withStyles } from "@material-ui/core";

const tempPhoto =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const SeatAssignment = props => {
  const [members, setMembers] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const isInitialMount = useRef(true);
  const assignedRef = useRef();
  useEffect(() => {
    if (isInitialMount.current) {
      // console.log("Initial Mount");
      setAssigned(props.meeting.seating);
      setMembers([
        { id: "dkdkdk", fullName: "Open", photoUrl: tempPhoto },
        ...props.meeting.activeMembers
      ]);
      isInitialMount.current = false;
    } else {
      // console.log("Not initial Mount");
    }
  }, [props.meeting.activeMembers, props.meeting.seating]);

  useEffect(() => {
    if (assigned.length > 0 && assignedRef.current.length > 0) {
      props.update(props.meeting.id, assigned);
    } else {
      assignedRef.current = assigned;
    }
  }, [assigned]);

  const handleSimple = (event, seat) => {
    for (let i = 0; i < members.length; i++) {
      if (event.target.value === members[i].id) {
        let newArr = [...assigned];
        newArr[seat] = members[i];
        setAssigned(newArr);
      }
    }
  };

  const createDropDowns = () => {
    let dropDowns = [];

    // Outer loop to create parent
    for (let i = 0; i < props.meeting.seats; i++) {
      dropDowns.push(
        <FormControl
          fullWidth
          className={props.classes.selectFormControl}
          key={i}
        >
          <InputLabel
            htmlFor="simple-select"
            className={props.classes.selectLabel}
          >
            Seat {i + 1} Assignment
          </InputLabel>
          <Select
            MenuProps={{
              className: props.classes.selectMenu
            }}
            classes={{
              select: props.classes.select
            }}
            value={assigned[i].id}
            onChange={e => {
              handleSimple(e, i);
            }}
            inputProps={{
              id: "seat1-select"
            }}
          >
            {members.map(member => {
              return (
                <MenuItem
                  classes={{
                    root: props.classes.selectMenuItem
                  }}
                  value={member.id}
                  key={member.id}
                >
                  {member.fullName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
    return dropDowns;
  };
  return (
    <div className={props.classes.block}>
      {assigned.length > 0 ? createDropDowns() : <h2>Please Wait...</h2>}
    </div>
  );
};

export default withStyles(extendedFormsStyle)(SeatAssignment);
