/* eslint-disable no-unused-vars */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore } from "react-redux-firebase";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//Meeting Components
import SeatAssignment from "../Components/SeatAssignment";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class MeetingDashboard extends React.Component {
  updateSeating = (meeting, seats) => {
    this.props.firestore.update(
      `${process.env.REACT_APP_CLIENT}MeetingTypes/${meeting}`,
      {
        seating: this.props.firestore.FieldValue.delete()
      }
    );
    this.props.firestore.update(
      `${process.env.REACT_APP_CLIENT}MeetingTypes/${meeting}`,
      {
        seating: seats
      }
    );
  };
  render() {
    const { classes, meetings } = this.props;
    return (
      <div>
        <GridContainer>
          {meetings.map((meeting, key) => (
            <GridItem xs={12} sm={12} md={6} key={key}>
              <Card>
                <CardHeader>
                  <h4 className={classes.cardTitle}>{meeting.name}</h4>
                </CardHeader>
                <CardBody>
                  <SeatAssignment
                    meeting={meeting}
                    update={this.updateSeating}
                  />
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    meetings:
      state.firestore.ordered[`${process.env.REACT_APP_CLIENT}MeetingTypes`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};
export default compose(
  withFirestore,
  firestoreConnect([
    {
      collection: `${process.env.REACT_APP_CLIENT}MeetingTypes`,
      orderBy: "name"
    }
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(MeetingDashboard));
