/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Datetime from "react-datetime";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, withFirebase } from "react-redux-firebase";
import Select from "react-select";
import Dropzone from "react-dropzone";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import avatar from "assets/img/faces/marc.jpg";

class MemberSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetings: [],
      member: this.props.member,
      updated: false,
      belongsTo: this.props.member.belongsTo
    };
  }

  componentDidMount() {
    const meetingsArray =
      this.props.meetings &&
      this.props.meetings.map(meeting => {
        return {
          value: meeting.id,
          label: meeting.name
        };
      });
    this.setState(prevState => ({
      meetings: meetingsArray
    }));
  }

  handleMultiple = (action, value) => {
    console.log(value);
    let memberBelongArray = [];

    switch (value.action) {
      case "select-option": {
        if (this.state.member.belongsTo) {
          memberBelongArray = [
            ...this.state.member.belongsTo,
            {
              id: value.option.value,
              value: value.option.value,
              name: value.option.label,
              label: value.option.label
            }
          ];
        } else {
          memberBelongArray = [
            {
              id: value.option.value,
              value: value.option.value,
              name: value.option.label,
              label: value.option.label
            }
          ];
        }
        this.setState(prevState => ({
          belongsTo: [
            ...this.state.belongsTo,
            {
              id: value.option.value,
              value: value.option.value,
              name: value.option.label,
              label: value.option.label
            }
          ],
          member: {
            ...prevState.member,
            belongsTo: memberBelongArray
          },
          updated: true
        }));
        break;
      }

      case "remove-value": {
        let newArray = this.state.member.belongsTo.filter(
          entry => entry.value !== value.removedValue.value
        );
        this.setState(prevState => ({
          belongsTo: newArray,
          member: {
            ...prevState.member,
            belongsTo: newArray
          },
          updated: true
        }));
        break;
      }
      case "clear": {
        let newArray = [];
        this.setState(prevState => ({
          belongsTo: newArray,
          member: {
            ...prevState.member,
            belongsTo: newArray
          },
          updated: true
        }));
        break;
      }
      default: {
        console.log("No Matches");
        break;
      }
    }
  };

  handleFormChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(prevState => ({
      member: {
        ...prevState.member,
        [name]: value
      },
      updated: true
    }));
  };

  handleImage = files => {
    var that = this;
    var storageRef = this.props.firebase
      .storage()
      .ref(`${process.env.REACT_APP_CLIENT}MemberImages/${files[0].name}`);
    var status = storageRef.put(files[0]);
    status.on(
      "state_changed",

      function progress(snapshot) {
        // console.log(snapshot.bytesTransferred);
      },

      function error(err) {
        console.log("An error has occurred " + err);
      },
      function complete() {
        // get url of file
        status.snapshot.ref.getDownloadURL().then(downloadURL => {
          that.setState(prevState => ({
            member: {
              ...prevState.member,
              photoUrl: downloadURL
            },
            updated: true
          }));
        });
      }
    );
  };

  updateProfile = () => {
    if (this.state.updated === true) {
      const newMember = {
        ...this.state.member
      };
      this.props.firestore.update(
        `${process.env.REACT_APP_CLIENT}Members/${newMember.id}`,
        { ...newMember }
      );
      for (let index = 0; index < this.state.belongsTo.length; index++) {
        this.props.firestore.update(
          `${process.env.REACT_APP_CLIENT}MeetingTypes/${this.state.belongsTo[index].id}`,
          {
            activeMembers: this.props.firestore.FieldValue.arrayUnion({
              firstName: this.state.member.firstName,
              lastName: this.state.member.lastName,
              photoUrl: this.state.member.photoUrl,
              position: this.state.member.position,
              id: this.state.member.id,
              fullName: `${this.state.member.firstName} ${this.state.member.lastName}`
            })
          }
        );
      }
      this.props.showTable();
    } else {
      this.props.showTable();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Edit Member</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="firstName"
                        inputProps={{
                          name: "firstName",
                          value: this.state.member.firstName,
                          onChange: this.handleFormChange
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="lastName"
                        inputProps={{
                          name: "lastName",
                          value: this.state.member.lastName,
                          onChange: this.handleFormChange
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Position Title"
                        id="position"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: "position",
                          value: this.state.member.position,
                          onChange: this.handleFormChange,
                          type: "text"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <GridContainer>
                  <GridItem xs={6}>
                    <FormControl
                      fullWidth={true}
                      className={classes.selectFormControl}
                    >
                      <p>Member Of</p>
                      <Select
                        // defaultValue={meetings}
                        value={this.state.member.belongsTo}
                        isMulti
                        name="memberOf"
                        options={this.state.meetings}
                        className="classes.select"
                        classNamePrefix="select"
                        onChange={this.handleMultiple}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <br />
          <GridItem xs={12} sm={12} md={4}>
            <Dropzone onDrop={this.handleImage} multiple={false}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                  <Card profile>
                    <CardAvatar profile>
                      <img src={this.state.member.photoUrl} alt="..." />
                    </CardAvatar>
                    <CardBody profile>
                      <input {...getInputProps()} />
                      {isDragActive
                        ? "Drop the file to upload"
                        : "To update the photo simply click here or drag and drop a new photo"}
                    </CardBody>
                  </Card>
                </div>
              )}
            </Dropzone>
          </GridItem>
        </GridContainer>
        {this.state.updated === true ? (
          <Button color="rose" round onClick={this.updateProfile}>
            Update Profile
          </Button>
        ) : (
          <Button color="rose" round onClick={this.updateProfile}>
            Close
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    meetings:
      state.firestore.ordered[`${process.env.REACT_APP_CLIENT}MeetingTypes`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};
const enhance = compose(
  withFirestore,
  withFirebase,
  firestoreConnect([
    { collection: `${process.env.REACT_APP_CLIENT}MeetingTypes` }
  ]),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withStyles(userProfileStyles)(MemberSetup));
