/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Board from "react-trello";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore } from "react-redux-firebase";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui icons

// Custom Imports
import NewCard from "./NewCard";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  }
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      seatedMembers: []
    };
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let availableMembers = [];
    if (prevProps.allStates !== this.props.allStates) {
      if (this.props.members && !this.state.data) {
        const index = this.props.seating.findIndex(
          object => object.id === this.props.allStates.meeting.simpleSelect
        );
        const placedMembers = this.props.seating[index].seatedMembers;
        this.props.members.map(member => {
          member.belongsTo.map(meeting => {
            // check if the meeting id matches the id passed in from simple select
            if (meeting.id === this.props.allStates.meeting.simpleSelect) {
              // Check if member is in placed
              if (
                placedMembers.length > 0 &&
                placedMembers.some(object => object.id === member.id)
              ) {
                //console.log("Do Nothing");
              } else {
                if (
                  member.termEnd.toMillis() >
                  this.props.firestore.Timestamp.now().toMillis()
                ) {
                  const fullName = member.firstName + " " + member.lastName;
                  const addMember = {
                    id: member.id,
                    displayName: fullName,
                    vote: member.canVote,
                    position: member.position,
                    photo: member.photoUrl
                  };
                  availableMembers.push(addMember);
                }
              }
            }
          });
        });

        const initialData = {
          lanes: [
            {
              id: "ACTIVE",
              title: "Active Members",
              style: {
                width: 280
              },
              cards: availableMembers
            },
            {
              id: "PLACED",
              title: "Seat Order",
              style: {
                width: 280
              },
              cards: placedMembers
            }
          ]
        };
        this.setState({
          data: initialData
        });
      }
    }
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails) {}

  onDataChange(newData) {
    if (newData.lanes[1].cards.length > 0) {
      this.setState({
        seatedMembers: newData.lanes[1].cards
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer
          justify="flex-start"
          direction="column"
          alignItems="center"
        >
          <GridItem xs={6}>
            <h4 className={classes.infoText}>
              Now lets place the members in their seating order...
            </h4>
          </GridItem>
          <GridItem xs={6}>
            {this.state.data && (
              <Board
                data={this.state.data}
                draggable
                laneDraggable={false}
                style={{
                  background: "white",
                  fontFamily: "Verdana",
                  height: "auto"
                }}
                className="boardContainer"
                handleDragEnd={this.handleDragEnd}
                onDataChange={this.onDataChange}
                customCardLayout
              >
                <NewCard />
              </Board>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    members: state.firestore.ordered[`${process.env.REACT_APP_CLIENT}Members`],
    seating: state.firestore.ordered[`${process.env.REACT_APP_CLIENT}Seating`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementDummy: () => dispatch(increment()),
    // onStoreResult: val => dispatch(addResult(val)),
    // onDeleteResult: resultId => dispatch(deleteResult(resultId))
  };
};
const enhance = compose(
  withFirestore,
  firestoreConnect([
    {
      collection: `${process.env.REACT_APP_CLIENT}Members`
    },
    { collection: `${process.env.REACT_APP_CLIENT}Seating` }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(withStyles(style)(Step2));
