export const firebaseConfig = {
  apiKey: "AIzaSyAuImQUvLCtkKTEl_VtzLMNA5lU-B1y0Fg",
  authDomain: "digivote-8c530.firebaseapp.com",
  databaseURL: "https://digivote-8c530.firebaseio.com",
  projectId: "digivote-8c530",
  storageBucket: "digivote-8c530.appspot.com",
  messagingSenderId: "182331568159",
  appId: "1:182331568159:web:f3a40dcf39c60fc2bee52a"
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};
